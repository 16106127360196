import React, { useRef } from 'react'; 
// import sections
import Hero from '../components/sections/Hero';
// import FeaturesTiles from '../components/sections/FeaturesTiles';
// import FeaturesSplit from '../components/sections/FeaturesSplit';
// import Testimonial from '../components/sections/Testimonial';
// import Cta from '../components/sections/Cta';

const Home = () => {

  const iframeRef = useRef(null);

  return (
    <>
      <Hero className="illustration-section-01" />

      <iframe
        src="https://app.fillout.com/flow/3SeGdczJK6us?__background=rgba(21%2C%2023%2C%2025%2C%201)&__formBackground=rgba(21%2C%2023%2C%2025%2C%201)"
        width="100%" 
        height="250px"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
        title="email capture for my landing page"
        ref={iframeRef}
        style={{display: 'none'}} 
        onLoad={() => {iframeRef.current.style.display = 'block'}}/>


      {/* <button 
        id="fillout-launch-button"
        onclick="__filloutOpen()"
        data-embed-type="popup"
        data-flow-url="https://app.fillout.com/flow/3SeGdczJK6us" 
        style={{cursor: "pointer !important", fontFamily: "Helvetica,Arial,sans-serif !important", padding: "20px !important", display: "inline-block", maxWidth: "100%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", textDecoration: "none", all: "unset", backgroundColor: "#3b82f6", color: "#FFFFFF", fontSize: "22px", borderRadius: "36px", fontWeight: "bold", textAlign: "center", margin: "0"}}
      >
        Open form
      </button>  */}

      {/* <div style={{position:"absolute",top:"0px",left:"0px",right:"0px",bottom:"0px"}}> <iframe src="https://app.fillout.com/flow/3SeGdczJK6us" width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0" title="email capture for my landing page"> </iframe> </div> */}
      {/* <FeaturesTiles />
      <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
      <Testimonial topDivider />
      <Cta split /> */}
    </>
  );
}

export default Home;